type CustomChangeEvent<T = HTMLElement> = Event & {
	target: T;
};

interface TrackingObject {
	event: string;
	subMenu?: string;
	eventCallback?: () => void;
	topMenu?: string;
}

(function () {
	const menu = document.querySelector('.Nav--Mobile');
	const button_open = document.querySelector('.Header--Link--Menu__Open');
	const button_close = document.querySelector('.Header--Link--Menu__Close');

	function close(): void {
		// Buttons
		button_close.classList.add('Header--Link--Menu__Hidden');
		button_open.classList.remove('Header--Link--Menu__Hidden');

		// Menu
		menu.classList.add('Nav--Mobile__Hidden');
		menu.setAttribute('aria-hidden', 'true');
		document.body.setAttribute('aria-hidden', 'false');

		// Body
		document.body.style.overflow = '';
		document.getElementById('root').style.overflow = '';
	}

	function open(): void {
		// Buttons
		button_open.classList.add('Header--Link--Menu__Hidden');
		button_close.classList.remove('Header--Link--Menu__Hidden');

		// Menu
		menu.classList.remove('Nav--Mobile__Hidden');
		menu.setAttribute('aria-hidden', 'false');
		document.body.setAttribute('aria-hidden', 'true');

		// Body
		document.body.style.overflow = 'hidden';
		document.getElementById('root').style.overflow = 'hidden';
	}

	function navCloseChild(element: HTMLElement): void {
		element.classList.remove('Nav--Child__Open');
		element.querySelector<HTMLInputElement>(
			'input[type="checkbox"]'
		).checked = false;
		element
			.querySelector('.Nav--Child--Open')
			.classList.remove('Nav--Child--Open__Open');
	}

	function navChildren({
		target,
	}: CustomChangeEvent<HTMLInputElement>): void {
		const navChild = target.closest('.Nav--Child');

		if (navChild.closest('.Nav--Child--Sub')) {
			navChild
				.closest('.Nav--Child--Sub')
				.querySelectorAll('.Nav--Child__Open')
				.forEach(navCloseChild);
		} else {
			// Parent container
			navChild
				.closest('.Nav--Mobile--Container')
				.querySelectorAll('.Nav--Mobile--Container > .Nav--Child__Open')
				.forEach(navCloseChild);
		}

		navChild.classList[target.checked ? 'add' : 'remove'](
			'Nav--Child__Open'
		);

		navChild
			.querySelector<HTMLLabelElement>('.Nav--Child--Open')
			.classList[target.checked ? 'add' : 'remove'](
				'Nav--Child--Open__Open'
			);

		navChild
			.querySelector<HTMLElement>('.Nav--Child--Sub')
			.classList[target.checked ? 'add' : 'remove'](
				'Nav--Child--Sub__Open'
			);
	}

	document.addEventListener('DOMContentLoaded', function (): void {
		button_open.addEventListener('click', open);
		button_close.addEventListener('click', close);
		document
			.querySelector('.Nav--Mobile--Shadow')
			.addEventListener('click', close);

		menu.querySelectorAll<HTMLInputElement>(
			'input[type="checkbox"]'
		).forEach(element => {
			element.addEventListener('change', navChildren);
			element.dispatchEvent(new Event('change'));
		});
	});

	document
		.getElementById('megaMenu')
		.addEventListener('click', (e: Event) => {
			e.stopPropagation();
			const target = e.target as HTMLElement;
			const trackingObject: TrackingObject = {
				event: 'megaMenu',
				topMenu: target.dataset.trackingTop,
			};

			if (target.classList.contains('Nav--Link')) {
				// Clicked a link e.g. Apple iPhone 15
				trackingObject['eventCallback'] = function () {
					document.location.href = target.href;
				};

				trackingObject.topMenu = target.dataset.trackingTop;
				trackingObject.subMenu = target.dataset.trackingSub;
			} else if (target.classList.contains('Nav--Link__ViewAll')) {
				trackingObject.topMenu = target.dataset.trackingTop;
				trackingObject.subMenu = 'View All';
			}

			globalThis.dataLayer.push(trackingObject);
		});

	document
		.getElementById('megaMenu--Mobile')
		.addEventListener('click', (e: Event) => {
			e.stopPropagation();
			e.stopImmediatePropagation();
			const target = e.target as HTMLElement;
			const trackingObject: TrackingObject = {
				event: 'megaMenu',
				topMenu: target.dataset.trackingTop,
			};

			if (target.type === 'checkbox') {
				return;
			}

			if (target.classList.contains('Nav--Child--Link')) {
				// Clicked a link e.g. Apple iPhone 15
				trackingObject['eventCallback'] = function () {
					document.location.href = target.href;
				};

				trackingObject.topMenu = target.dataset.trackingTop;
				trackingObject.subMenu = target.dataset.trackingSub;
			} else if (target.classList.contains('Nav--Link__ViewAll')) {
				trackingObject.topMenu = target.dataset.trackingTop;
				trackingObject.subMenu = 'View All';
			}

			globalThis.dataLayer.push(trackingObject);
		});
})();
